import React from 'react'
import './iconfont.less'
class MyIcon extends React.Component {
  render() {
    const { type, style } = this.props
    return (
      <span className={ `iconfont ${ type }` } style={style}></span>
    )
  }
}
export default MyIcon
