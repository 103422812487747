import { Toast } from 'antd-mobile'

export const timeout = 10 // 分钟，10分钟超时

export const processRequest = (promise, success, fail) => {
  promise.then(({ code, data, msg }) => {
    if(code === 200) {
      success && success(data)
    } else {
      if (fail && typeof fail === 'function') {
        fail(msg)
      } else {
        Toast.info(msg)
      }
    }
  })
}

export const trackEvent = (_category, _event) => {
  // const _hmt = window._hmt
  // if (_hmt) _hmt.push(['_trackEvent', _category, _event])
  const gtag = window.gtag
  if (gtag) {
    gtag('event', _category, {
      'event_category': _category,
      'event_label': _event,
      'value': 1
    });
  }
}

export const createColumn =  (title, key, render, o = {}) => ({
  title,
  key,
  dataIndex: key,
  render,
  ...o
})
export const setShare = props => {
  const { link = '', content, title } = props

  setTimeout(() => {
    const shareInfo = {
      content: content,
      title: title,
      url  : link,
      icon : '',
      cb   : function() {}
    }
    if(window._wx) {
      window._wx.init(shareInfo)
    }
    console.log('set share success')
  }, 500)
}

export const set16ToRGB = str => {
  var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
  if(!reg.test(str)) return
  let newStr = (str.toLowerCase()).replace(/\#/g,'')
  let len = newStr.length
  if (len == 3) {
     let t = ''
     for (var i = 0; i < len; i++) {
        t += newStr.slice(i,i+1).concat(newStr.slice(i,i+1))
     }
     newStr = t
  }
  let arr = []; //将字符串分隔，两个两个的分隔
  for (var i = 0; i < 6; i = i+2){
    let s = newStr.slice(i, i + 2)
    arr.push(parseInt("0x" + s))
  }
  // return 'rgb(' +arr.join(",")  + ')';
  return arr
}

export const checkIsLight = rgb => {
  const level = rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114
  return level >= 192
}

export const scrollToTop = () => {
  document.querySelector('#root').scrollTo(0, 0)
}

export const setTitle = title => {
  window.document.title = title.length > 70 ? title.substr(0, 70).concat('...') : title
}
