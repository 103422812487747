const imageToBlob = base64 => {
    const byteCharacters = window.atob(
        base64.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
        type: undefined,
    });
    return blob
}
// export const copyImageToClipboard = async (base64, cb) => {
//     try { 
//         const blob = imageToBlob(base64)
//         const clipboardItem = new window.ClipboardItem({ 'image/jpeg': blob })
//         await window.navigator.clipboard.write([ clipboardItem ])
//         cb('ok')
//     } catch(e) { 
//         console.log(e)
//         cb('error')
//     }
// }

export const copyImageToClipboard = async (base64Data, callback) => {
    try {
      // Convert base64 to blob
      const blob = await fetch(base64Data).then(r => r.blob());
      // Modern browsers - use Clipboard API
      if (navigator.clipboard && navigator.clipboard.write) {
        const item = new window.ClipboardItem({ 'image/png': blob });
        await navigator.clipboard.write([item]);
        callback('ok');
        return;
      }
      
      // Fallback - create temporary element
      const img = new Image();
      img.src = base64Data;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        
        canvas.toBlob(blob => {
          // Create clipboard data
          const data = new window.ClipboardItem({ 'image/png': blob });
          navigator.clipboard.write([data])
            .then(() => callback('ok'))
            .catch(() => callback('error'));
        });
      };
    } catch (err) {
      console.error('Failed to copy image:', err);
      callback('error');
    }
  };
export const saveImg = base64 => {
    const blob = imageToBlob(base64)
    const file_name = `wenanmen_com_memes_${Date.now()}.jpg`
    // for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, file_name)
    }
    // for Non-IE (chrome, firefox etc.)
    else {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.target = '_blank'
        a.download = file_name
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url)
    } 
}
export const localImageToBase64 = (file, callback) => {
    if (file.type.startsWith('image/')) {
        const reader = new FileReader()

        reader.onload = e => {
            const arrayBuffer = e.target.result
            const urlReader = new FileReader()
            urlReader.onload = e2 => {
                const imgSrc = e2.target.result
                callback(imgSrc)
            }
            urlReader.readAsDataURL(file)
        }
        reader.readAsArrayBuffer(file)
    }
}