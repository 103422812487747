import React from 'react'
import { Message } from 'antd'
import CopyToClipboard from 'component/copyToClipbord'
import './index.less'

class ExpandImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideShare: false
    }
  }
 
  
  handleCopyShare() {
    Message.success('复制完毕，快去分享吧！')
    setTimeout(() => {
      this.setState({
        hideShare: true
      })
    }, 2000)
  }
  componentDidMount() {}
  render() {
    const { hideShare } = this.state

    return (
      <div className={`meme-share-tips ${ hideShare ? 'hide' : '' }`}>
        <span><strong>orz</strong> 求分享，推荐好友一起玩儿吧！</span>
        <CopyToClipboard 
          value={`👯‍♀️不仅仅是梗图，更是你我的共同语言。点🔗来#文案门，👉 http://u3v.cn/5H8Nap 让我们用图说话！㊙️`} 
          completeCopy={ this.handleCopyShare.bind(this) }>
          <div className='meme-share-tips-copy'>复制推荐</div>
        </CopyToClipboard>
      </div>
    )
  }
}
export default ExpandImage

