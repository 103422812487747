import React from 'react'
import { Button, Icon } from 'antd'
import { send } from 'mulan-lib'
import { browserHistory } from 'react-router' 
import { processRequest } from 'lib'
import { ossLink } from 'config'
import ExpandImage from 'component/expand-image' 
import './index.less'

class MemePop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      local: false,
    }
  }
  componentDidMount() {}
  handleRankMemes() {
    this.setState({
      loading: true
    })
    processRequest(
      send('/api/admin/common/func', {
        url: 'memes/rank',
        data: {
          len: 1
        }
      }),
      data => {
        const meme = data[0]
        const { src, userinfo } = meme
        meme.pic = src
        if (!!userinfo) {
          meme.pic = `${ ossLink }${ src }?x-oss-process=image/resize,w_400,limit_0`
        }
        
        setTimeout(() => {
          this.setState({
            loading: false,
            local: true,
            localMeme: meme,
          })
        }, 500)
      }
    )
  }
  handleToNew(item) {
    window.open(`/memes/new/${ item._id }`)
  }
  handleToFree() {
    browserHistory.push(`/memes/free`)
  }
  handleToCreate(memes) {
    const { onToNew, onToFree, onClose } = this.props
    const { tempId = '' } = memes
    if (tempId === '0' || tempId === '2ad666ce66c18c02077952216385063f') {
      onClose()
      this.handleToFree()
      return
    }
    processRequest(
      send('/api/admin/common/func', {
        url: 'memet/id',
        data: { tempId }
      }),
      data => {
        onClose()
        this.handleToNew(data)
      }
    )
  }
  render() {
    const { meme = '', onClose } = this.props
    const { loading = false, local, localMeme } = this.state
    const { tempId, pic } = !!local ? localMeme : meme

   
    return (
      <div className='meme-pop'>
        <ExpandImage pic={ pic } />
        <div className='meme-pop-action'>
          <Button loading={ loading } icon='reload' className='meme-pop-action-copy' onClick={ this.handleRankMemes.bind(this) } >随一个</Button>
          <Button type='primary' className='meme-pop-action-create' onClick={ this.handleToCreate.bind(this, !!local ? localMeme : meme)}>做同款<Icon type="swap-right" /></Button>
        </div>
        <div className='pop-closable' onClick={onClose}><Icon type="close-circle" /></div>
      </div>
    )
  }
}
export default MemePop