

// export const apiDomain = 'localhost:8080'
// export const apiDomain = 'wenanmen.com'
//
export const apiDomain = 'w.wenanmen.com'

export const baseApiUrl = `https://${ apiDomain }`

export const ossLink = 'https://vocs001.oss-accelerate.aliyuncs.com'
