import React from 'react'
import { Button, Modal, Switch, Icon, Message, Popconfirm } from 'antd'
import MyIcon from 'component/my-icon'
import ExpandImage from 'component/expand-image' 
import { getStore, send } from 'mulan-lib'
import fabric, { handleLockMoving } from './utils/fabric'
import { localImageToBase64 } from './utils/img'
import { textConfig, imgConfig, fontList } from './utils/config'
import { processRequest } from 'lib'
import FontFaceObserver from 'fontfaceobserver'
import Text from './text'
import Image from './image'
import SettingBtn from './setting-btn'
import MemePop from './meme-pop'
import './index.less'

const ButtonGroup = Button.Group

class Creator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fontFamily: fontList[0].font,
      list: [],
      canvasHeight: 0,
      translateY: 0,
      isSync: true,
    }
    this.textConfig = textConfig
    this.imgConfig = imgConfig
    this.canvasEL = null
    this.fileInputRef = React.createRef()
    this.uploadTs = 0
  }
  handleSaveRecord(base64PNG, id) {
    const { isSync } = this.state
    const userinfo = getStore('userinfo')
    const uid = getStore('uid')
    let words = ''
    this.canvasEL.getObjects().forEach(item => {
      const { type, text } = item
      if (type === 'text') {
        words += text
      }
    })
    let allowUpload = true

    if (!!this.uploadTs && (Date.now() - this.uploadTs) < 60000) {
      allowUpload = false
    }
    
    if (uid && words.length > 0 && allowUpload) {
      processRequest(
        send('/api/client/f/upload/base64', {
          pic: base64PNG
        }),
        data => {
          processRequest(
            send('/api/admin/common/func', {
              url: 'memes/add',
              data: {
                uid,
                userinfo,
                src: data,
                source: 'utools',
                tempId: id,
                name: words,
                isSync,
              }
            }),
            data => {
              console.log(id)
              this.uploadTs = Date.now()
            }
          )
        },
        err => {
          console.log('上传失败...', err)
        }
      )
     
    }
    // console.log(userinfo, uid, isSync)
  }
  handleCreatePNG() {
    const { id } = this.props
    const pngurl = this.canvasEL.toDataURL({
      format: 'png',
      multiplier: 2,
      quality: 1
    })
    this.handleHot(id)
    this.handlePopPic(pngurl)
    this.handleSaveRecord(pngurl, id)
  }
  handleHot(id) {
    processRequest(
      send('/api/admin/common/func', {
        url: 'memet/hot',
        data: {
          id
        }
      }),
      data => {
        console.log(id, '已添加热度')
      }
    )
  }
  handlePresetCanvas(image) {
    const canvasSize = {
      w: 400,
      h: 500,
    }
    const clientWidth = window.document.documentElement.clientWidth
    // if (clientWidth < 1000) {
    //   canvasSize.w = clientWidth
    //   canvasSize.h = clientWidth
    // }
    fabric.Image.fromURL(image, replyImage => {
      // console.log(replyImage)
      // 计算图片的宽高比
      const aspectRatio = replyImage.width / replyImage.height;

      const scaleX = canvasSize.w / replyImage.width
      const scaleY = canvasSize.w / parseFloat(aspectRatio) / replyImage.height

      this.canvasEL = new fabric.Canvas('canvas', {
        backgroundColor: '#fff',
        width: canvasSize.w,
        selection: false,
        // scale: 2
      })
      this.canvasEL.setBackgroundImage(replyImage, () => {
        this.canvasEL.renderAll()
      }, {
        scaleX,
        scaleY,
        imageSmoothing: true
      })
      this.canvasEL.setWidth(replyImage.getScaledWidth())
      this.canvasEL.setHeight(replyImage.getScaledHeight())

      // handleLockMoving(this.canvasEL)
      // initAligningGuidelines(this.canvasEL)
    }, { crossOrigin: 'anonymous' })
  }
  handleLoadFromJSON(json) {
    this.canvasEL = new fabric.Canvas('canvas', {
      backgroundColor: '#fff',
      selection: false,
    })
    const { objects, backgroundImage } = json
    // console.log(json)
    fabric.Image.fromURL(backgroundImage.src, replyImage => {
      this.canvasEL.loadFromJSON({...json, objects: []}, () => {
        // this.canvasEL.renderAll()
      })
      this.canvasEL.setBackgroundImage(replyImage, () => {
        this.canvasEL.renderAll()
      }, {
        scaleX: backgroundImage.scaleX,
        scaleY: backgroundImage.scaleY,
        imageSmoothing: true
      })

      this.canvasEL.setWidth(replyImage.getScaledWidth())
      this.canvasEL.setHeight(replyImage.getScaledHeight())
      
      // handleLockMoving(this.canvasEL)
      // initAligningGuidelines(this.canvasEL)

      objects.forEach(item => {
        const { type } = item
        if (type === 'text') {
          const { text, ...config } = item
          this.handleCreateText(text, config)
        } else if (type === 'image') {
          const { src, ...config } = item
          this.handleCreateImage(src, config)
        }
      })
    })
  }
  componentDidMount() {
    const { model } = this.props
    if (!!model && (model === 'edit' || model === 'new')) {
      const { pic, presetJSON } = this.props
      if (!!presetJSON) {
        console.log('load json')
        this.handleLoadFromJSON(JSON.parse(presetJSON))
      } else if (!!pic) {
        console.log('load pic')
        this.handlePresetCanvas(pic)  
      }
    }
  }
  handleCopyJSON() {
    const { onJSONBTNClick } = this.props
    const jsonData = this.canvasEL.toJSON({ compressed: false })
    const jsonStr = JSON.stringify(jsonData)

    !!onJSONBTNClick && onJSONBTNClick(jsonStr)
  }
  handlePushList(item, type) {
    const { list = [] } = this.state
    list.push({
      ...item,
      type,
      id: Date.now()
    })
    this.setState({
      list
    }, () => {
      // this.canvasEL.renderAll()
    })
  }
  handleSetFont({ font }) {
    this.setState({
      fontFamily: font
    }, () => {
      const fontFace = new FontFaceObserver(font)
      this.canvasEL.getObjects().forEach(item => {
        const { type, text } = item
        if (type === 'text') {
          fontFace.load(text, 5000).then(() => {
            item.set({
              fontFamily: font
            })
            this.canvasEL.renderAll()
          })
        }
      })
    })
  }
  handleSetWhiteSpace({ top, bottom }) {
    let { canvasHeight = 0, translateY } = this.state
    if (!!!canvasHeight) {
      canvasHeight = this.canvasEL.getHeight()
      this.setState({
        canvasHeight
      })
    } 
    console.log(translateY)
    const newHeight = canvasHeight + top + bottom
    this.canvasEL.setDimensions({ height: newHeight })
    const background = this.canvasEL.backgroundImage
    this.canvasEL.getObjects().forEach(item => {
      item.set({
        top: item.top - translateY + top
      })
    })
    background.set({
      top: background.top - translateY + top
    })
    
    this.setState({
      translateY: top
    })
   this.canvasEL.setBackgroundColor('white', this.canvasEL.renderAll.bind(this.canvasEL))
    
  }
  handlePresetFont(config) {
    const { fontFamily } = this.state
    config.fontFamily = fontFamily
    return config
  }
  handleCreateText(_word, _config) {
    const { fontFamily } = this.state
    const word = !!_word ? _word : `TEXT#${this.state.list.length + 1}`
    const text = new fabric.Text(word, this.handlePresetFont(!!_config ? _config : this.textConfig))
    const fontFace = new FontFaceObserver(fontFamily)
    fontFace.load(word).then(() => {
      this.canvasEL.add(text)
    })

    this.handlePushList({
      text,
      word,
    }, 'text')
  }
  handleCreateImage(src, config) {
    fabric.Image.fromURL(src, replyImage => {
      const ratio = replyImage.width / replyImage.height
      const rectWidth = 100
      if (!!config) {
        replyImage.set(config)
      } else {
        replyImage.set({
          ...this.imgConfig,
          scaleX: rectWidth * ratio / replyImage.width,
          scaleY: rectWidth / replyImage.height,
        })
      }
      
      this.canvasEL.add(replyImage)
      this.handlePushList({ src, img: replyImage }, 'image')
    }, { crossOrigin: 'anonymous' })
  }
  handleCloseModal() {
    const { modal } = this.state
    modal.destroy()
  }
  handlePopPic(pic) {
    const modal = Modal.confirm({
      title: '',
      maskClosable: true,
      content: (<MemePop pic={ pic } onClose={ this.handleCloseModal.bind(this) } />),
      icon: false,
      okButtonProps:{ style: { display: 'none' } },
      cancelButtonProps:{ style: { display: 'none' } },
    })
    this.setState({modal})
  }

  handleSelectItem(item) {
    this.canvasEL.discardActiveObject()
    this.canvasEL.setActiveObject(item)
    this.canvasEL.renderAll()
  }
  handleDeleteItem(item, id) {
    const { list } = this.state
    this.canvasEL.remove(item)
    this.canvasEL.renderAll()
    this.setState({
      list: list.filter((item) => item.id !== id)
    })
  }
  openFileDialog = () => {
    if (this.fileInputRef.current) this.fileInputRef.current.click();
  }
  handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      localImageToBase64(file, base64 => {
        this.handleCreateImage(base64)
      })
    }
    event.target.value = ''
  }
  handleClearAll() {
    const { list } = this.state
    list.forEach(item => {
      const { text, img, type } = item
      if (type === 'text') {
        this.handleDeleteItem(text)
      } else if (type === 'image') {
        this.handleDeleteItem(img)
      }
    })
    this.setState({
      list: []
    })
  }
  handleSyncChange(checked) {
    this.setState({
      isSync: checked
    })
  }
  render() {
    const { pic = '', showJSONBTN = false } = this.props
    const { list = [], isSync } = this.state

    return (
      <div className='meme-creator'>
        <div className='meme-creator-content'>
          <div className='meme-creator-content-preview'>
            <canvas id='canvas' width={400} />
          </div>
          <div className='meme-creator-content-tools' >
            <div className='meme-creator-content-tools-action' >
              <ButtonGroup>
                <Button type='ghost' onClick={() => this.handleCreateText()}>
                  <MyIcon type="icon-tianjiawenzi" />文字
                </Button>
                <Button type='ghost' onClick={this.openFileDialog.bind(this)}>
                  <MyIcon type="icon-tianjiatupian_huaban" />图片
                  <input
                    type="file"
                    accept="image/*"
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                  />
                </Button>
                <SettingBtn fontFamily={ this.state.fontFamily  } 
                            onChangeFont={ this.handleSetFont.bind(this) }
                            onChangeWhiteSpace={ this.handleSetWhiteSpace.bind(this) }
                             />
              </ButtonGroup>
            </div>
            <div className='meme-creator-content-tools-container' >
              <div className='meme-creator-content-tools-container-img' >
              {
                list.filter(({ type }) => type === 'image').map((item, index) => {
                  const { type, id } = item
                  return (
                    <div className='meme-creator-content-tools-container-item' key={id}>
                      <Image item={item}
                            index={index}
                            config={this.imgConfig}
                            canvas={this.canvasEL}
                            onFocus={this.handleSelectItem.bind(this)}
                            onDelete={this.handleDeleteItem.bind(this)}
                          />
                    </div>
                  )
                })
              }
              </div>
              {
                list.length === 0 && (
                  <div className='meme-creator-content-tools-container-empty'>
                    <p>请添加文字 / 图片</p>
                  </div>
                )
              }
              {
                list.filter(({ type }) => type === 'text').map((item, index) => {
                  const { type, id } = item
                  return (
                    <div className='meme-creator-content-tools-container-item' key={id}>
                       <Text item={item}
                          index={index}
                          font={ this.state.fontFamily }
                          config={this.textConfig}
                          canvas={this.canvasEL}
                          onFocus={this.handleSelectItem.bind(this)}
                          onDelete={this.handleDeleteItem.bind(this)}
                        />
                    </div>
                  )
                })
              }
            </div>

            <div className='meme-creator-content-tools-footer'>
              <ButtonGroup>
                <Button type='ghost' onClick={this.handleCreatePNG.bind(this)}>
                  <MyIcon type="icon-xiazai-L" />下载
                </Button>
                <Popconfirm
                  title="确定要清除？"
                  onConfirm={ this.handleClearAll.bind(this) }
                  onCancel={ () => { console.log('close') }}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button type='ghost'>
                    <MyIcon type="icon-qingchu" />清除
                  </Button>
                </Popconfirm>
              </ButtonGroup>
              <div className='meme-creator-content-tools-footer-sync'>
                  <span className='meme-creator-content-tools-footer-sync-text'>
                    <Icon type="cloud-upload" /> 创作区
                    </span>
                  <div>
                  <Switch size="small" checkedChildren="开" unCheckedChildren="关" checked={ isSync } onChange={ this.handleSyncChange.bind(this) } />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default Creator