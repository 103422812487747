import React from 'react'
import { Row, Col, Popover } from 'antd'
import './index.less'


class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      links: [
        {
          name: '沙雕导航网',
          link: 'https://shadiao.pro/'
        },
        {
          name: '星书签导航',
          link: 'https://www.polarisss.cn'
        }
      ],
      innerLinks: [
        {
          name: '首页',
          link: 'https://www.wenanmen.com/'
        },
        {
          name: '探索文案',
          link: 'https://www.wenanmen.com/kw'
        },
        {
          name: '文案圈子',
          link: 'https://www.wenanmen.com/quan'
        },
        {
          name: '迷因 · memes',
          link: 'https://www.wenanmen.com/memes'
        },
        {
          name: 'GPT',
          link: 'https://www.wenanmen.com/gpt'
        },
      ]
    }
  }
  render() {
    const { links, innerLinks } = this.state
    return (
      <div className='footer-content'>
        <Row type='flex' gutter={ 50 } justify='space-around'>
          <Col xs={{ span: '0' }} md={{ span: '8' }} style={{ color: '#fff', textAlign: 'left' }}>
            <h3 className='footer-content-head' style={{ textAlign: 'left', width: '85px' }}>站内导航</h3>
            <div className='footer-content-body'>
              {
                innerLinks.map(o => {
                  return (
                    <div key={ o.name }><a href={ o.link } target='_blank'>{ o.name }</a></div>
                  )
                })
              }
            </div>
          </Col>
          <Col xs={{ span: '0' }} md={{ span: '8' }} style={{ color: '#fff', textAlign: 'left' }}>
            <h3 className='footer-content-head' style={{ textAlign: 'left', width: '85px' }}>更多推荐</h3>
           
            <div className='footer-content-body'>
              <Popover
                placement="right"
                forceRender={true}
                content={ <img alt='小程序：文案门' src={ require('img/qrcode_m.jpg') } width='150' />}
                title="微信扫一扫，体验小程序"
                trigger="hover"
                ><a>文案门 · 小程序</a>
              </Popover>
            </div>
            <div className='footer-content-body'>
              <a href='https://www.zhihu.com/people/wenanmen' target='_blank'>文案门 · 知乎</a>
            </div>
            <div className='footer-content-body'>
              <a href='https://x.com/site_wenanmen' target='_blank'>文案门 · X</a>
            </div>
            
          </Col>
          <Col xs={{ span: '0' }} md={{ span: '8' }} style={{ color: '#fff' }}>
            <div className='flex flex-justify-end flex-align-center'>
              <div>
                <h3 className='footer-content-head'>公众号</h3>
                <div className='footer-content-body'>
                  <img alt='关注文案门' src={ require('img/qrcode.jpg') } width='130' />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className='footer-content-links'>
          友情链接：{
            links.map(o => {
              return (
                <a key={ o.name } href={ o.link } target='_blank'>{ o.name }</a>
              )
            })
          }
        </div>
        <div className='mp-contact'>
          <div className='mp-contact-item email'><a href='mailto:xfcdxg_zq@163.com'>商务合作 or 提示建议</a></div>
          <div className='mp-contact-item'><a href='/privacy-policy' target='_blank'>隐私条例</a></div>
          <div className='mp-contact-item'><a href='https://beian.miit.gov.cn/' target='_blank' >沪 ICP 备 2020033875 号-2</a></div>
          <div className='mp-contact-item'>
            <a style={{ textAlign: 'justify' }} href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014119' target='_blank' >
              <img src={ require('img/gongan.png') }  style={{ width: '.12rem', marginRight: '0.06rem' }}/>沪公网安备 31011202014119 号
            </a>
          </div>
        </div>
      </div>
    )
  }
}



{/* <div className='footer-content-body'>
              <a href='https://u.tools/plugins/detail/%E6%96%87%E6%A1%88%E9%97%A8/' target='_blank'>文案门 · uTools</a>
            </div>
            <div className='footer-content-body'>
              <a href='https://u.tools/plugins/detail/MEMEs%E6%A2%97%E5%9B%BE%E5%88%B6%E4%BD%9C/' target='_blank'>MEMEs梗图制作 · uTools</a>
            </div> */}


export default Footer
