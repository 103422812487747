import React, { Component } from 'react'
import addVconsole from "utils/addvconsole";
import { browserHistory } from 'react-router'
import { Tooltip, Badge, Affix, message, Col, Row, Input, Icon, BackTop, Popover } from 'antd'
import { processRequest, trackEvent } from 'lib'
import { getStore, setStore, send } from 'mulan-lib'
import UserMenu from 'component/user-menu'
import RandomNickname from '@sugarfish/random-nickname'
import Footer from './footer'
import RootSider from './root-sider'
import objectFit from 'object-fit'
import './index.less'

const { Search } = Input

class Root extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchVal: '',
      showMobileMenu: false,
      isRootPage: true,
    }
  }
  componentDidMount() {
    addVconsole(); //唤起控制台
    const { location } = this.props
    const { query, pathname } = location
    const { q, r = '' } = query
    console.log('isRootPage', pathname === '/' || pathname.indexOf('/memes/new') >= 0 || pathname.indexOf('/memes/free') >= 0, pathname)
    this.setState({
      isRootPage: pathname === '/' || pathname.indexOf('/memes/new') >= 0 || pathname.indexOf('/memes/free') >= 0,
      rootPath: pathname
    })
    if (!!q) {
      this.setState({
        searchVal: q
      })
    }

    this.handleCheckUser(r)
    objectFit.polyfill({
  		selector: 'img', // this can be any CSS selector
  		fittype: 'cover', // either contain, cover, fill or none
  		disableCrossDomain: 'true' // either 'true' or 'false' to not parse external CSS files.
  	});
  }

  static getDerivedStateFromProps(props, state) {
    const pathname = props.location.pathname
    if (pathname != state.rootPath) {
      return {
        ...state,
        rootPath: pathname,
        isRootPage: pathname === '/' || pathname.indexOf('/memes/new') >= 0 || pathname.indexOf('/memes/free') >= 0
      }
    }
    return null
  }
  handleCheckUser(r) {
    const uid = getStore('uid')
    if (uid) {
      const utype = getStore('utype')
      const userinfo = getStore('userinfo')
      if (userinfo) {
        this.setState({
          userinfo
        })
      } else {
        this.handleGetUserInfo(uid)
      }
    } else {
      this.handleRegisterLocalUser(r)
    }
  }
  handleGenerateUid() {
    return parseInt(Date.now() + Math.random() * 100000)
  }
  handleGenerateNickname() {
    const options = {//非必传
      describeList: [], //形容词字典，支持自定义，非必传
      nounList: [] //名称字典，支持自定义， 非必传
    }
    const res = new RandomNickname(options)
    return res.getRandomName()
  }
  handleRegisterLocalUser(r) {
    const postData = {
      localId: this.handleGenerateUid(),
    }
    if (!!r) {
      trackEvent('main', 'r_login')
      postData.r = r
    }
    // 注册本地用户
    processRequest(
      send('/api/admin/common/func', {
        url: 'user/local',
        data: postData
      }),
      data => {
        // console.log(data)
        setStore('uid', data)
        setStore('utype', 'local')
        this.handleSetUserInfo(data)
      }
    )
  }
  handleGetUserInfo(uid) {
    // userinfo
    processRequest(
      send('/api/admin/common/func', {
        url: 'userinfo',
        data: {
          uid,
        }
      }),
      data => {
        const { userinfo } = data
        setStore('userinfo', userinfo)
        this.setState({
          userinfo,
        })
      }
    )
  }
  handleSetUserInfo(uid) {
    processRequest(
      send('/api/admin/common/func', {
        url: 'userinfo/operate',
        data: {
          nickname: this.handleGenerateNickname(),
          uid,
        }
      }),
      data => {
        // setStore('userinfo', data)
        this.handleGetUserInfo(uid)
      }
    )
  }
  handleToggleMenu() {
    const { showMobileMenu } = this.state
    this.setState({
      showMobileMenu: !showMobileMenu
    })
  }
  render() {
    const { children } = this.props
    const { showMobileMenu, isRootPage } = this.state
    return (
      <div className='root' >
        <div className='root-header'>
          <div className='root-header-container'>
            <Row style={{ width: '100%' }} type="flex" justify="space-between" align='middle'>
              <Col lg={ 8 } xs={ 0 } sm={ 0 } md={ 0 } >
                <div className='root-header-container-guide'>
                  <a href='/kw'><Icon type='search' /> 探索</a>

                  <span></span>
                  <a href='/quan'>圈子</a>
                  <span></span>
                  <Badge dot offset={[2,-2]}><a href='/memes'>梗图</a></Badge>
                  <span></span>
                  <a href='/gpt'>GPT</a>
                </div>
              </Col>
              <Col lg={ 8 } xs={ 8 } sm={ 8 } md={ 8 } >
                <div className='root-header-container-logo'>
                  <a href='https://www.wenanmen.com/'>
                    <img src={ require('img/pc_logo.png') } alt='文案门'/>
                    <div className='root-header-container-logo-tips'>
                      <Icon type='link' /> 首页
                    </div>
                  </a>
                </div>
              </Col>
              <Col lg={ 8 } xs={ 0 } sm={ 0 } md={ 0 } >

                <div className='root-header-container-tools'>
                  <Popover
                    placement="bottom"
                    content={(
                      <div>
                        <div style={{ fontSize: '.12rem', color: '#bbb' }}>Windows 用户</div>
                        <div style={{ fontSize: '.12rem', backgroundColor: '#888', color: '#fff', padding: '.01rem .06rem', borderRadius: '.03rem', margin: '.03rem 0 .06rem 0' }}>Ctrl + D</div>
                        <div style={{ fontSize: '.12rem', color: '#bbb' }}>Mac 用户</div>
                        <div style={{ fontSize: '.12rem', backgroundColor: '#888', color: '#fff', padding: '.01rem .06rem', borderRadius: '.03rem', margin: '.03rem 0 .06rem 0' }}>⌘ + D</div>
                      </div>
                    )}
                    title="收藏本站"
                    trigger="hover"
                    ><Icon type="star" theme="filled" />
                  </Popover>
                  <span></span>
                  <Popover
                    placement="bottom"
                    content={ <img alt='关注文案门' src={ require('img/qrcode.jpg') } width='150' />}
                    title="微信扫一扫，尝鲜早一步"
                    trigger="hover"
                    ><Icon type="wechat" />
                  </Popover>
                  <span></span>
                  <Badge dot offset={[ 3, 2]}><UserMenu /></Badge>
                </div>
              </Col>
              <Col  lg={ 0 } xs={ 8 } sm={ 8 } md={ 8 }>
                <div onClick={ this.handleToggleMenu.bind(this) } className='root-header-container-tools' style={{ padding: '.2rem 0' }}>
                  <Icon type={ showMobileMenu ? 'close' : 'menu' } />
                </div>
              </Col>
            </Row>
          </div>
          {
            showMobileMenu && (
              <div className='root-header-menu'>
                <a href='/'><div className='root-header-menu-item'><Icon type="home" /> 首页</div></a>
                <a href='/kw'><div className='root-header-menu-item'><Icon type="search" /> 探索</div></a>
                <a href='/quan'><div className='root-header-menu-item'><Icon type="smile" /> 圈子</div></a>
                <a href='/memes'><div className='root-header-menu-item'><Icon type="bars" /> 梗图</div></a>
                <a href='/gpt'><div className='root-header-menu-item'><Icon type="robot" /> GPT</div></a>
              </div>
            )
          }
        </div>
        <div className='root-body'>
          <div className={ `root-body-content ${ isRootPage ? '' : 'child-page' } ` }>{ children }</div>
          { !isRootPage && (<div className='root-body-sider'><RootSider location={ this.props.location } /></div>) }
        </div>
        <div className='root-footer'>
          <Footer />
        </div>
        <div className='mobile-tips'>
          <img src={ require('img/icon.png') } /> 微信 或 PC端 搜索<span>文案门</span>获得更好体验
        </div>

        <BackTop target={ () => window.document.body } style={{ bottom: '0.5rem' }} />
      </div>
    )
  }
}
export default Root

{/* <BackTop target={ () => window.document.body } >
<div className='fixed-layer'><Icon type="home" theme="filled" /></div>
</BackTop>
<BackTop onClick={ () => { return alert('message') } } target={ () => window.document.body } style={{ bottom: '1rem' }} >
<div className='fixed-layer'><Icon type="message" theme="filled" /></div>
</BackTop> */}
