import React from 'react'
import { Input, Button } from 'antd'
import { hashHistory } from 'react-router' 
import Creator from '../creator'
import MemeShareTips from 'component/meme-share-tips'
import { localImageToBase64 } from '../creator/utils/img'
import './index.less'

const { TextArea } = Input

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pic: '',
      _id: '2ad666ce66c18c02077952216385063f'
    }
    this.fileInputRef = React.createRef();
  }
  componentDidMount() {
   
  }
  openFileDialog = () => {
    if (this.fileInputRef.current) this.fileInputRef.current.click();
  }
  handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      localImageToBase64(file, base64 => {
        this.setState({
          pic: base64
        })
      })
    }
    event.target.value = ''
  }
  handleClear() {
    this.setState({
      pic: ''
    })
  }
  handleKongbai() {
    this.setState({
      pic: 'https://vocs001.oss-accelerate.aliyuncs.com/temp/kong.jpeg'
    })
  }
  render() {
    const { _id, pic } = this.state
    return (
      <div className='meme-new'>
        <div className='meme-new-info'>
          <div className='meme-new-info-name'>
            <Button shape="circle" type='ghost' icon='left' size='small' onClick={ () => hashHistory.push('/') }></Button>
            自定义 MEME
          </div>
          <div className='flex flex-justify-between flex-align-center'>
            <div>
              {
                !!pic ? (
                  <Button type='dashed' icon='close' onClick={this.handleClear.bind(this)}>更换图片</Button>
                ) : (
                <Button type='ghost' icon='upload' onClick={this.openFileDialog.bind(this)}>
                  上传图片
                  <input
                    type="file"
                    accept="image/*"
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                  />
                </Button>
                )
              }
              {
                !!pic ? (
                  <div/>
                ) : (
                <Button style={{ marginLeft: '12px' }} icon='border' type='ghost' onClick={this.handleKongbai.bind(this)}>
                  空白
                </Button>
                )
              }
            </div>
            <MemeShareTips/>
          </div>
        </div>
        
        <div>
          {
            pic && <Creator id={ _id } pic={ pic } model='new' />
          }
        </div>
      </div>
    )
  }
}
export default Home
