import React from 'react'
import { browserHistory } from 'react-router' 
import { getStore, setStore, send } from 'mulan-lib'
import { Radio, Button, Skeleton, Message, Input } from 'antd'
import { processRequest, setTitle } from 'lib'
import GAds from 'lib/component/gads'
import MemeItem from './meme-item'
import MemesRank from './memes-rank'
import './index.less'

const { Search } = Input;

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      from: 0,
      max: 6,
      sortBy: 'random',
      loading: true,
      isEnd: false,
      likeList: getStore('like') || []
    }
    setTitle('MEMES|迷因|梗图 制作')
  }
  handleToNew(item) {
    window.open(`/memes/new/${ item._id }`)
  }
  handleToFree() {
    browserHistory.push(`/memes/free`)
  }
  componentDidMount() {
    const { kw } = this.props.params
    if (!!kw) {
      this.handleSearch(kw)
    } else {
      // this.handleGetList()
      this.handleRandomList()
    }
  }

  handleRandomList() {
    this.setState({
      kw: '',
      list: [],
      loading: true
    })
    processRequest(
      send('/api/admin/common/func', {
        url: 'memet/rank',
        data: {
          len: 6,
        }
      }),
      data => {
        setTimeout(() => {
          this.setState({
            loading: false,
            list: data,
            isEnd: false,
          })
        }, 200)
      }
    )
  }
  handleGetList() {
    const { from, max, sortBy, kw = '' } = this.state

    processRequest(
      send('/api/admin/common/func', {
        url: 'memet/list',
        data: {
          from: from,
          max: max,
          sortBy,
          kw: [kw],
        }
      }),
      data => {
        const { list } = this.state
        if (data.length == 0) {
          this.setState({
            loading: false,
            isEnd: true,
            from: from - max
          }, () => {
            Message.warn('已经没有啦！')
          })
        } else {
          setTimeout(() => {
            this.setState({
              loading: false,
              list: data,
              isEnd: false,
            })
          }, 500)
          
        }
        
      }
    )
  }
  handleSortByChange(e) {
    this.setState({
      from: 0,
      list: [],
      kw: '',
      sortBy: e.target.value,
      loading: true,
    }, () => {
      const { sortBy, likeList } = this.state
      if (sortBy === 'like') {
        this.setState({
          loading: false,
          list: likeList,
        })
      }
      else if (sortBy === 'random') {
        this.handleRandomList()
      } 
      else {
        this.handleGetList()
      }
    })
  }
  handleGetPrev() {
    const { from } = this.state
    if (from > 0) {
      this.setState({
        from: from - this.state.max,
        loading: true,
      }, this.handleGetList.bind(this))
    }
  }
  handleGetMore() {
    this.setState({
      from: this.state.from + this.state.max,
      loading: true,
    }, this.handleGetList.bind(this))
  }
  handleSearch(value) {
    this.setState({
      kw: value,
      from: 0,
      sortBy: 'hot',
      loading: true,
    }, this.handleGetList.bind(this))
  }
  handleTagClick(tag) {
    this.handleSearch(tag)
  }
  handleKWChange(e) {
    this.setState({
      kw: e.target.value,
    })
  }
  handleLike(meme) {
    const { likeList = [] } = this.state 
    likeList.push(meme)
    this.setState({
      likeList
    }, () => {
      setStore('like', likeList)
    })
  }
  handleUnLike(meme) {
    const { likeList = [] } = this.state 
    const index = likeList.findIndex(item => item._id === meme._id)
    likeList.splice(index, 1)
    this.setState({
      likeList
    }, () => {
      setStore('like', likeList)
    })
  }
  render() {
    const { loading = false, list, sortBy, from, isEnd = false, kw = '', likeList = [] } = this.state
    return (
      <div className='meme'>
        <div className='meme-search'>
          <Search disabled={ loading } value={ kw } allowClear placeholder="请输入关键字，如：四格图" onChange={ this.handleKWChange.bind(this) } onSearch={ this.handleSearch.bind(this) }/>
        </div>
        <div className='meme-tools'>
          <div>
            <Radio.Group size='small' disabled={ loading } defaultValue="hot" value={ sortBy } buttonStyle="solid" onChange={ this.handleSortByChange.bind(this) }>
              <Radio.Button value="random">🎲</Radio.Button>
              <Radio.Button value="hot">热门</Radio.Button>
              <Radio.Button value="new">最新</Radio.Button>
              <Radio.Button value="like">收藏</Radio.Button>
            </Radio.Group>
            <Button size='small' className='meme-tools-free' type='ghost' icon='smile' onClick={ this.handleToFree.bind(this) }>自定义</Button>
          </div>
          <Search size='small' disabled={ loading } value={ kw } allowClear style={{ width: '300px' }} placeholder="请输入关键字，如：四格图" onChange={ this.handleKWChange.bind(this) } onSearch={ this.handleSearch.bind(this) }/>
        </div>
        <div className='meme-list'>
          { !!loading && (
            [1,2,3,4,5,6].map((n, index) => {
              return (
                <div key={ index } className='meme-list-item skeleton'>
                  <div className='meme-list-item-skeleton-pic'></div>
                  <Skeleton loading={loading} active paragraph={{ rows: 2 }} />
                </div>
              )
            })
            ) 
          }
          { list.length === 0 && !loading && (<div className='meme-list-loading'>暂无收藏</div>) }
          {
            list.map((item, index) => {
              const { _id } = item
              return (
                <div key={`${_id}${ index }`} className='meme-list-item'>
                  <MemeItem meme={ item } 
                            onTagClick={ this.handleTagClick.bind(this) } 
                            onToNew={ this.handleToNew.bind(this) } 
                            onLike={ this.handleLike.bind(this) } 
                            onUnLike={ this.handleUnLike.bind(this) } 
                            isLike={ likeList.findIndex(item => item._id === _id) !== -1 }
                            hideHot={ sortBy === 'like' }
                            />
                </div>
              )
            })
          }
        </div>
        {
          (() => {
            if (sortBy !== 'like' && sortBy !== 'random' && list.length > 0) {
              return (
                <div className='meme-action'>
                  <Button disabled={ from === 0 || loading }  onClick={this.handleGetPrev.bind(this)}>上一页</Button>
                  <Button disabled={ isEnd || loading }  onClick={this.handleGetMore.bind(this)}>下一页</Button>
                </div>
              )
            } else if (sortBy === 'random') {
              return (
                <div className='meme-action'>
                  <Button disabled={ loading } onClick={this.handleRandomList.bind(this)}>✋🏻 拼手气 ✋🏻</Button>
                </div>
              )
            } else {
              return  <div className='meme-action' />
            }
          })()
         
        }
        <MemesRank len={6} />        
        <GAds slot='1110868974' />
      </div>
    )
  }
}
export default Home
