import React from 'react'
import { send } from 'mulan-lib'
import { browserHistory } from 'react-router' 
import { Input, Tag } from 'antd'
import Creator from '../creator'
import GAds from 'lib/component/gads'
import MyIcon from 'component/my-icon'
import MemeShareTips from 'component/meme-share-tips'
import { processRequest, setTitle } from 'lib'
import { ossLink } from 'config'
import './index.less'

const { TextArea } = Input

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pic: '',
      name: '',
      tags: '',
      count: 0,
      json: '',
    }
  }
  componentDidMount() {
    const { _id } = this.props.params
    if (!!_id) {
      this.setState({
        _id,
      }, () => {
        this.handleGetTemp(_id)
      })
    }
  }
  handleGetTemp(id) {
    processRequest(
      send('/api/admin/common/func', {
        url: 'memet',
        data: {
          id
        }
      }),
      data => {
        if (!!data) {
          const { pic, name, tags, count, json = '' } = data
          setTitle(`${ name } memes | 迷因 | 梗图 制作`)
          this.setState({
            pic,
            name,
            tags,
            count,
            json,
          })
        }
      }
    )
  }
  handleReset() {
    this.setState({
      pic: '',
      name: '',
      tags: '',
      count: 0,
      json: '',
    })
  }
  handleChangePic(e) {
    this.setState({
      pic: e.target.value
    })
  }
  handleChangeName(e) {
    this.setState({
      name: e.target.value
    })
  }
  handleChangeTags(e) {
    this.setState({
      tags: e.target.value
    })
  }
  handleChangeCount(e) {
    this.setState({
      count: e.target.value
    })
  }
  handleTagClick(tag) {
    browserHistory.push(`/memes/${ tag }`)
  }
  render() {
    const { _id, pic, name, tags, count, json } = this.state
    return (
      <div className='meme-new'>
        <div className='meme-new-info'>
          <div className='meme-new-info-name'>
            { name }
            <div className='meme-new-info-name-hot'>
              <MyIcon type='icon-fire' /><span>{ count }</span>
            </div>
            
          </div>
          <div className='flex flex-justify-between flex-align-center'>
            <div className='meme-new-info-tags'>
              { 
                tags.split(',').map((tag, index) => {
                  return <Tag title={ `点击搜索：${ tag }` } key={index} onClick={ () => this.handleTagClick(tag) }>{ tag }</Tag>
                })
              }
            </div>
            <MemeShareTips />
          </div>
        </div>
        
        <div>
          {
            pic && <Creator id={ _id } pic={ `${ ossLink }${ pic }` } model='new' presetJSON={ json } />
          }
        </div>
        <GAds slot='3612338415' />
      </div>
    )
  }
}
export default Home
