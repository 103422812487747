import React from 'react'
import { Button, Message, Icon } from 'antd'
import { saveImg, copyImageToClipboard } from '../utils/img'
import ExpandImage from 'component/expand-image' 
import './index.less'

class MemePop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      local: false,
    }
  }
  componentDidMount() {}
    
  handleSavePic(pic) {
    saveImg(pic)
  }
  hanldeCopyPic(pic) {
      if (!!window.utools) {
        window.utools.copyImage(pic)
        Message.success('图片已复制')
      } else {
        copyImageToClipboard(pic, reply => {
          if (reply === 'ok') {
            Message.success('图片已复制')
          } else {
            Message.error('复制失败')
          }
        })
        // Message.warn('当前环境不支持复制图片')
      }
    }
  render() {
    const { pic = '', onClose } = this.props
   
    return (
      <div className='meme-pop'>
        <ExpandImage pic={ pic } />
        <div className='meme-pop-action'>
          <Button icon='copy' className='meme-pop-action-copy' onClick={ this.hanldeCopyPic.bind(this, pic) } >一键复制</Button>
          <Button type='primary' className='meme-pop-action-create' onClick={ this.handleSavePic.bind(this, pic)}>下载梗图 <Icon type="cloud-download" /></Button>
        </div>
        <div className='pop-closable' onClick={onClose}><Icon type="close-circle" /></div>
      </div>
    )
  }
}
export default MemePop