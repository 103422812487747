import React from 'react'
import { Button } from 'antd'
import { send } from 'mulan-lib'
import { processRequest } from 'lib'
import MemesItem from './meme-item'
import { trackEvent } from 'lib'
import BlockTitle from 'lib/component/block-title'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }
  componentDidMount() {
    this.handleRankMemes()
  }
  handleRankMemes() {
    const { len = 8 } = this.props
    this.setState({
      loading: true
    })
    processRequest(
      send('/api/admin/common/func', {
        url: 'memes/rank',
        data: {
          len,
        }
      }),
      data => {
        setTimeout(() => {
          this.setState({
            loading: false,
            memeslist: data,
            // isEnd: false,
          })
        }, 500)
      }
    )
  }
  
  render() {
    const { block = false, title = '创作区' } = this.props
    const { memeslist = [], loading = false } = this.state
    return (
      <div className={`memes-rank ${ block ? 'block' : '' }`}>

          <BlockTitle title={title} extra='换一批' secondary noPadding arrowType='refreash' arrowStyle={{ width: '15px' }}
                          onClick={
                            () => {
                              trackEvent('main', 'meme-rank')
                              this.handleRankMemes()
                            }
                          } />
          
          <div className='memes-rank-list'>
            {
              memeslist.length === 0 && (
                <div className='memes-rank-list-bgtext'>创作区</div>
              )
            }
            { loading && ( <div className='memes-rank-list-loading'>加载中...</div> ) }
            {
              memeslist.map((item, index) => {
                return (
                  <MemesItem key={index} memes={item} {...this.props} />
                )
              })
            }
          </div>
        </div>
    )
  }
}
export default Home
