import React from 'react'
import { Input, Tag, Button } from 'antd'
import MyIcon from 'component/my-icon'
import { ossLink } from 'config'
import ColorThief from 'colorthief'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleProcessInfo() {
    const colorThief = new ColorThief()
    const imgEl = this.refs.image

    const mainColor = colorThief.getColor(imgEl)
    this.setState({
      mainColor,
    })
  }
  
  render() {
    const { mainColor = [255,255,255] } = this.state
    const { meme, onTagClick, onToNew, onLike, onUnLike, isLike = false, hideHot = false } = this.props
    const { pic, _id, name, tags, count } = meme
    return (
      <div className='meme-item'>
         <div className='meme-item-img' style={{ backgroundColor: `rgb(${ mainColor[0] }, ${ mainColor[1] }, ${ mainColor[2] })` }}  onClick={() => onToNew(meme)}>
            <img onLoad={ this.handleProcessInfo.bind(this) } crossOrigin="anonymous" ref='image' src={ `${ ossLink }${ pic }?x-oss-process=image/resize,w_150,limit_0` } alt='' />
            {
              hideHot ? null : (
                <div className='meme-item-img-hot'>
                  <MyIcon type='icon-fire' /><span>{ count }</span>
                </div>
              )
            }
            <div className='meme-item-img-hot'>
              <MyIcon type='icon-fire' /><span>{ count }</span>
            </div>
          </div>
          <div className='meme-item-info'>
            <div className='meme-item-info-name' onClick={() => onToNew(meme)}>{name}</div>
            <div className='meme-item-info-tags'>
              { 
                tags.split(',').map((tag, index) => {
                  // <Tag key={`${ tag }${ index }`} onClick={ () => onTagClick(tag) }>{ tag }</Tag>
                  return (
                    <span key={`${ tag }${ index }`} onClick={ () => onTagClick(tag) }>{ tag }</span>
                  )
                })
              }
            </div>
            <div className='meme-item-info-action'>
              <div className={`meme-item-info-action-item love ${ isLike ? 'ed' : '' }` } onClick={() => isLike ? onUnLike(meme) : onLike(meme)} >
                {
                  isLike ? <MyIcon type='icon-shoucang1' style={{ fontSize: '24px' }} /> : <MyIcon type='icon-shoucang2' style={{ fontSize: '24px' }} />
                }
              </div>
              <div className='meme-item-info-action-item create' onClick={() => onToNew(meme)}>
                制作<MyIcon type='icon-swapright_line' style={{ fontSize: '20px' }} />
              </div>
            </div>
          </div>
      </div>
    )
  }
}
export default Home
