import React from 'react'
import { Icon } from 'antd'
import './index.less'

class ExpandImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      imageHeight: 0,
      imageLoaded: false,
    }
  }
  handleImageLoad = (e) => {
    this.setState({
      imageHeight: e.target.height,
      imageLoaded: true,
      expanded: false,
    })
  }
  
  toggleExpand = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }))
  }
  handleProcessInfo() {
    
  }
  
  componentDidMount() {}
  render() {
    const { pic = '', maxDisplayHeight = 400 } = this.props
    const { expanded, imageHeight = 0, imageLoaded=false } = this.state
    const isOutOfHeight = imageHeight > maxDisplayHeight
    return (
      <div className='expand-image'>
        <div 
             className={`expand-image-container ${ !isOutOfHeight ? 'flex flex-justify-center flex-align-center' : '' }` }
          style={{
            maxHeight: expanded ? `${ imageHeight/100 }rem` : `${ maxDisplayHeight/100 }rem`,
          }}>
          <img src={ pic } onLoad={ this.handleImageLoad.bind(this) } />
          {imageLoaded && isOutOfHeight && !expanded && (
            <div onClick={this.toggleExpand} className='expand-image-container-full'>
             <div className='flex flex-justify-center flex-align-center'><Icon type="caret-down" />查看完整图片<Icon type="caret-down" /></div>
            </div>
          )}
        </div>
        
        {expanded && imageLoaded && isOutOfHeight && (
          <div onClick={this.toggleExpand} className='expand-image-collapse' >
            <Icon type="caret-up" />收起<Icon type="caret-up" />
          </div>
        )}
      </div>
    )
  }
}
export default ExpandImage

