import React from 'react'
import { Message, Modal, Button, Icon } from 'antd'
import { ossLink } from 'config'
import MemePop from '../meme-pop'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {}
  handleCloseModal() {
    const { modal } = this.state
    modal.destroy()
  }
  handlePopMeme(memes) {
    const modal = Modal.confirm({
      title: '',
      // className: 'pic-modal',
      maskClosable: true,
      onOk: () => {
        this.handleToCreate(memes)
      },
      content: <MemePop meme={ memes } { ...this.props } onClose={ this.handleCloseModal.bind(this) } />,
      icon: false,
      okButtonProps:{ style: { display: 'none' } },
      cancelButtonProps:{ style: { display: 'none' } },
    })
    this.setState({modal})
  }
  render() {
    const { memes = [] } = this.props
    const { src, userinfo } = memes
    let pic = src
    if (!!userinfo) {
      pic = `${ ossLink }${ pic }?x-oss-process=image/resize,w_400,limit_0`
    } 
    // <img onLoad={ this.handleProcessInfo.bind(this) } crossOrigin="anonymous" ref='image' src={ pic } alt={ name } />
    return (
      <div className='memes-rank-list-item' onClick={this.handlePopMeme.bind(this, { ...memes, pic })}>
        <div className='memes-rank-list-item-mask'>
          <div className='memes-rank-list-item-mask-body'>
            <Icon type="fullscreen" style={{ color: '#fff', fontSize: '.24rem' }} />
            <div>查看完整</div>
          </div>
          {!!userinfo && (
            <div className='memes-rank-list-item-mask-user'>
              <img src={userinfo.headimgurl} className='memes-rank-list-item-mask-user-avatar' />
              <span className='memes-rank-list-item-mask-user-name'>{userinfo.nickname}</span>
            </div>
          )}
        </div> 
        <div className='memes-rank-list-item-pic' style={{ backgroundImage: `url(${pic})` }}>
          <img src={pic} />
          <div className='memes-rank-list-item-pic-img-bg' />
        </div>
      </div>
    )
  }
}
export default Home
