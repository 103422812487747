import React from 'react'
import { Menu, Dropdown, Input, Button, Icon } from 'antd'
import MyIcon from 'component/my-icon'
import { fontList } from '../utils/config'
import './index.less'
import { WhiteSpace } from 'antd-mobile'

const { SubMenu } = Menu;
const { TextArea } = Input

class SettingBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      whiteSpace: '无'
    }
  } 
  handlePresetFont(o) {
    const { onChangeFont } = this.props
    onChangeFont(o)
  }
  handleChangeWhiteSpace(o) {
    const { name, val } = o
    const { onChangeWhiteSpace } = this.props
    this.setState({ whiteSpace: name }, () => {
      onChangeWhiteSpace(val)
    })
  }
  render() {
    const { fontFamily } = this.props
    const { whiteSpace } = this.state
    const menu = (
      <Menu>
        <SubMenu title="切换字体  ">
          {
            fontList.map(o => (
              <Menu.Item key={ o.font } onClick={ this.handlePresetFont.bind(this, o) }>
                <div className={ `flex flex-justify-between flex-align-center` }>
                  { o.name }
                  {
                    fontFamily === o.font && (
                      <Icon type="check" />
                    )
                  }
                </div>
              </Menu.Item>
            ))
          }
           <Menu.Item disabled={ true }>特殊需求请留言</Menu.Item>
        </SubMenu>
        <SubMenu title="画布留白">
          {
            [{ name: '无', val: { top: 0, bottom: 0 } },
             { name: '上', val: { top: 50, bottom: 0 } },
             { name: '下', val: { top: 0, bottom: 50 } },
             { name: '上下', val: { top: 50, bottom: 50 } }]
             .map(o => (
              <Menu.Item key={ o.name } onClick={ () => this.handleChangeWhiteSpace(o) }>
                 <div className={ `flex flex-justify-between flex-align-center` }>
                  { o.name }
                  {
                    whiteSpace === o.name && (
                      <Icon type="check" />
                    )
                  }
                </div>
              </Menu.Item>
            ))
          }
        </SubMenu>

      </Menu>
    );
    return (
      <Dropdown overlay={menu} placement="bottomLeft">
        <Button type='ghost' shape="round">
          <MyIcon type="icon-Settings" />
        </Button>
      </Dropdown>
    )
  }
}
export default SettingBtn