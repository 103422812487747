export const textConfig = {
    fontSize: 20,
    stroke: '#000000', // 描边颜色
    strokeWidth: 8, // 描边宽度
    strokeLineCap: 'round',
    strokeLineJoin: 'round',
    paintFirst: 'stroke',
    charSpacing: 20,
    fill: '#ffffff', // 填充颜色
    left: 50,
    top: 50,
    fontWeight: 'normal',
    fontStyle: 'normal',
    minScaleLimit: 0.3,
    fontSmoothing: 'antialiasing',
    fontFamily: 'Noto Sans SC',
    // fontWeight: 'bold',
}

export const imgConfig = {
    left: 100,
    top: 100,
    angle: 0,
    centeredRotation: true,
    originX: 'center',
    originY: 'center'
}

export const fontList = [
    { font: 'Douyin Sans', name: '抖音美好体' }, 
    { font: 'YEFONTXiaoShiTou', name: '也字工厂小石头' }, 
    { font: 'MuzaiPixel', name: '目哉像素体' },
    { font: 'Child Fun Sans Demo', name: '游趣体' },
    { font: 'bailubangbangshouxieti', name: '白路棒棒手写体' },
]